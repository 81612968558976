<template>
    <div class="settings" @scroll="onScroll">
        <!-- <div class="page-header"> -->
        <StackRouterHeaderBar :title="$translate('SETTINGS')" :show-title="true" />
        <!-- </div> -->
        <div class="body">
            <div class="section" :key="section.title" v-for="section in sections">
                <div class="section-title" v-html="$translate(section.title)" />
                <div :key="item.key" v-for="item in section.items">
                    <div class="item flex-row items-center flex-between" @click="item.handler && item.handler()">
                        <div class="item-title" v-html="$translate(item.key.toUpperCase())" />
                        <div v-if="item.hasOwnProperty('value')">
                            <Toggler v-if="typeof item.value === 'boolean'" v-model="item.value" class="no-touch" />
                            <div v-else v-html="item.value" />
                        </div>
                    </div>
                    <img class="example-img m-b-12" v-if="item.img" :src="item.img" alt="" />
                </div>
            </div>
        </div>
        <div class="app-version" v-html="appVersion" />
    </div>
</template>

<script>
import userService from '@/services/user'

export default {
    name: 'SettingsPage',
    data: () => ({
        snackbar: false,
        showTitle: false,
    }),
    computed: {
        me() {
            return this.$store.getters.me
        },
        sections() {
            if (!this.me) return

            return [
                {
                    title: 'NOTIFICATIONS',
                    items: [
                        {
                            key: 'notified',
                            value: this.me.notified,
                            handler: () => this.update('notified'),
                        },
                    ],
                },
                {
                    title: 'ACCOUNT',
                    items: [
                        // {
                        //     key: 'dormant',
                        //     handler: () => this.$stackRouter.push({ name: 'DormantPage' }),
                        // },
                        // {
                        //     key: 'change_password',
                        //     handler: () => this.$stackRouter.push({ name: 'ChangePasswordPage' }),
                        // },
                        {
                            key: 'logout',
                            handler: () => this.askLogout(),
                        },
                        {
                            key: 'dormant',
                            handler: () => this.askDormant(),
                        },
                        {
                            key: 'dropout',
                            handler: () => this.askDropout(),
                        },
                    ],
                },
            ]
        },
        appVersion() {
            const { app_version: version } = this.$store.getters.device || {}

            return `앱 버전 ${version || ''}`
        },
    },
    methods: {
        onScroll(e) {
            this.showTitle = e.target.scrollTop > 52
        },
        askLogout() {
            this.$modal
                .basic({
                    title: 'LOGOUT',
                    body: this.$translate('LOGOUT_BODY'),
                    buttons: [
                        {
                            label: 'CANCEL',
                            class: 'btn-default',
                        },
                        {
                            label: 'LOGOUT',
                            class: 'btn-primary',
                        },
                    ],
                })
                .then(selectedBtnIdx => {
                    if (selectedBtnIdx === 1) this.$store.dispatch('signOut')
                })
        },
        toggleLocale() {
            this.$store.dispatch('toggleLocale')
        },
        async update(key) {
            const payload = this.me
            payload[key] = !payload[key]
            try {
                await userService.update(payload)
                this.$toast.success('UPDATED')
            } catch (e) {
                this.$toast.error(e.data)
            }
            this.$store.dispatch('loadMe')
        },
        async askDormant() {
            await this.$modal.custom({
                component: 'ModalSelectDormantReason',
                options: {
                    chat: this.$store.getters.chat,
                    status: status,
                    profilePhotoMessage: this.profilePhotoMessage,
                },
            })
        },
        askDropout() {
            const onConfirm = async () => {
                if (this.me.unpaid_cancel_fee.length === 0) {
                    await this.$modal
                        .basic({
                            body:
                                '휴면 상태로 전환할 경우 내 프로필은 그 누구에게도 공개되지 않으며, 향후 재가입시 번거로운 절차를 피할 수 있습니다. 휴면하지 않고 탈퇴하시겠어요?',
                            buttons: [
                                {
                                    label: '탈퇴하기',
                                    class: 'btn-primary',
                                },
                                {
                                    label: '휴면하기',
                                    class: 'btn-primary',
                                },
                            ],
                        })
                        .then(idx => {
                            if (idx === 0) {
                                this.$modal.custom({
                                    component: 'ModalDropout',
                                    options: {},
                                })
                            } else if (idx === 1) {
                                this.$modal.custom({
                                    component: 'ModalSelectDormantReason',
                                    options: {},
                                })
                            }
                        })
                } else {
                    this.$modal.basic({
                        body:
                            '납부하지 않은 약속 취소/미진행 수수료가 있습니다. 수수료를 납부한 이후에 탈퇴 신청이 가능합니다.',
                        buttons: [
                            {
                                label: 'CONFIRM',
                                class: 'btn-primary',
                            },
                        ],
                    })
                }
            }

            if (this.$isTester()) {
                this.$modal.custom({
                    component: 'ModalDropout',
                })
            } else {
                onConfirm()
            }
        },
    },
    created() {
        this.$nativeBridge.postMessage({
            action: 'setBackgroundColor',
            value: {
                top: '#FAF0E1',
                bottom: '#FFF9EF',
            },
        })
    },
}
</script>
<style scoped lang="scss">
::v-deep .stack-router-header-bar {
    .left-btn > .material-icons {
        color: $grey-08;
    }

    &.show-title {
        z-index: 3;
        position: fixed;
        top: 0;
        background-color: white;
        border-bottom: solid 1px $grey-03;
        margin-bottom: 16px;

        .material-icons {
            color: $grey-08 !important;
        }
    }
}
</style>
